import { forwardRef, useState } from 'react';
import Input, { InputTypes } from 'components/forms/Input';

const PasswordField = forwardRef<HTMLInputElement, InputTypes>(
  (props = {}, ref) => {
    const [hidden, setHidden] = useState(true);

    return (
      <Input
        type={hidden ? 'password' : 'text'}
        leftIcon="ri-lock-line"
        rightIcon={`${
          hidden ? 'ri-eye-line' : 'ri-eye-close-line'
        } cursor-pointer`}
        onRightIconClick={() => setHidden(!hidden)}
        label="Password"
        ref={ref}
        {...props}
      />
    );
  }
);
PasswordField.displayName = 'PasswordField';

export default PasswordField;
