export default function errorParser(err) {
  if (err) {
    const { errors } = err;
    if (err && errors) {
      if (errors.error) {
        return errors.error[0];
      }
  
      if (err.title) {
        return err.title;
      }
  
      const arrayedError = Object.keys(errors);
      const tempError = errors[arrayedError[0]];
      return typeof tempError === 'object' ? tempError[0] : tempError;
    }
  }

  return 'General Server Error';
}
